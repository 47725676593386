<template>
  <div>
    <vx-card actionable class="cardx" title="ExEd SPOC Dashboard">
      <vs-row type="flex">
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="3"
        >
          <vs-select class="selectExample" label="Team" v-model="selected_team">
            <vs-select-item
              :key="index"
              :value="item"
              :text="item"
              v-for="(item, index) in teams"
            />
          </vs-select>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="2"
          style="margin-top: 1.5%"
        >
          <vs-button color="dark" type="filled" @click="getTeamData"
            >GO</vs-button
          >
        </vs-col>
      </vs-row>
      <vx-card actionable class="cardx" style="margin-top: 3%" v-if="loaded">
        <vs-row type="flex" style="margin-top: 2%">
          <vs-col
            vs-order="1"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="6"
            style="font-size: 19px"
          >
            <b>Call Logs</b>
          </vs-col>
          <vs-col
            vs-order="1"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="6"
            style="font-size: 19px"
          >
            <b>Work Logs</b>
          </vs-col>
        </vs-row>
        <vs-table :data="team_data">
          <template slot="thead">
            <vs-th>Spoc Name</vs-th>
            <vs-th>Team</vs-th>
            <vs-th>Missed Calls</vs-th>
            <vs-th>Untracked</vs-th>
            <vs-th>To Update</vs-th>
            <vs-th>Call Logs Today</vs-th>
            <vs-th>Untapped</vs-th>
            <vs-th>Delays</vs-th>
            <vs-th>Today</vs-th>
            <vs-th>Waiting</vs-th>
            <vs-th>Net Enquiries</vs-th>
            <vs-th>MHPs</vs-th>
          </template>
          <template slot-scope="{ data }">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td>
                {{ data[indextr].spoc_name }}
              </vs-td>
              <vs-td>
                {{ data[indextr].city_classification }}
              </vs-td>
              <vs-td :data="data[indextr].first_name">
                <a @click="getData(tr.user_id, 'missed_calls')">
                  {{ data[indextr].missed_calls }}
                </a>
              </vs-td>
              <vs-td :data="data[indextr].first_name">
                <a @click="getData(tr.user_id, 'untracked')">
                  {{ data[indextr].untracked_calls }}
                </a>
              </vs-td>
              <vs-td :data="data[indextr].first_name">
                <a @click="getData(tr.user_id, 'to_update')">
                  {{ data[indextr].to_update_calls }}
                </a>
              </vs-td>
              <vs-td :data="data[indextr].first_name">
                <a @click="getData(tr.user_id, 'call_logs_today')">
                  {{ data[indextr].today_calls_logs }}
                </a>
              </vs-td>
              <vs-td :data="data[indextr].first_name">
                <a @click="getData(tr.user_id, 'untapped')">
                  {{ data[indextr].untapped }}
                </a>
              </vs-td>
              <vs-td :data="data[indextr].first_name">
                <a @click="getData(tr.user_id, 'delays')">
                  {{ data[indextr].delays }}
                </a>
              </vs-td>
              <vs-td :data="data[indextr].first_name">
                <a @click="getData(tr.user_id, 'today')">
                  {{ data[indextr].today }}
                </a>
              </vs-td>
              <vs-td :data="data[indextr].first_name">
                <a @click="getData(tr.user_id, 'waiting')">
                  {{ data[indextr].waiting_calls }}
                </a>
              </vs-td>
              <vs-td :data="data[indextr].first_name">
                <a
                  @click="
                    getData(
                      tr.user_id,
                      'net_enquiries',
                      data[indextr].net_enquiries
                    )
                  "
                  >{{ data[indextr].net_enquiries }}</a
                >
              </vs-td>
              <vs-td :data="data[indextr].first_name">
                <a @click="getData(tr.user_id, 'mhps')">
                  {{ data[indextr].mhp }}
                </a>
              </vs-td>
            </vs-tr>
            <vs-tr v-show='hidetotal'>
              <vs-td>
                {{ total.Header }}
              </vs-td>
              <vs-td></vs-td>
              <vs-td>
                {{ total.missed_calls }}
              </vs-td>
              <vs-td>
                {{ total.untracked }}
              </vs-td>
              <vs-td>
                {{ total.to_update }}
              </vs-td>
              <vs-td>
                {{ total.call_logs_today }}
              </vs-td>
              <vs-td>
                {{ total.untapped }}
              </vs-td>
              <vs-td>
                {{ total.delays }}
              </vs-td>
              <vs-td>
                {{ total.today }}
              </vs-td>
              <vs-td>
                {{ total.waiting }}
              </vs-td>
              <vs-td>
                {{ total.net_enquiries }}
              </vs-td>
              <vs-td>
                {{ total.mhps }}
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </vx-card>
    </vx-card>
    <SpocDashboardCallLogPopup></SpocDashboardCallLogPopup>
    <SpocDashboardAdminToUpdate></SpocDashboardAdminToUpdate>
    <SpocDashboardAdminWorkLog></SpocDashboardAdminWorkLog>
    <SpocDashboardCmNetEnquiries></SpocDashboardCmNetEnquiries>
  </div>
</template>

<script>
import eventbus from "../components/eventbus";
import constants from "../../constants.json";
import axios from "axios";
import SpocDashboardCallLogPopup from "../components/pagesComponents/SpocDashboardAdminCallLog.vue";
import SpocDashboardAdminToUpdate from "../components/pagesComponents/SpocDashboardAdminToUpdate.vue";
import SpocDashboardNetEnquiries from "../components/pagesComponents/SpocDashboardNetEnquiries.vue";
import SpocDashboardCmNetEnquiries from "../components/pagesComponents/SpocDashboardCMNetenquiries.vue";
import SpocDashboardAdminWorkLog from "../components/pagesComponents/SpocDashboardAdminWorkLog.vue";

export default {
  data() {
    return {
        selected_team: "",
        teams: [],
        dashboard_data : [],
        team_data: [],
      hidetotal: false, 
      loaded: false,
      total: {
        missed_calls: [],
        untracked: [],
        to_update: [],
        call_logs_today: [],
        untapped: [],
        delays: [],
        today: [],
        waiting: [],
        net_enquiries: [],
        mhps: [],
      },
    };
  },
  components: {
     SpocDashboardCallLogPopup,
    SpocDashboardAdminToUpdate,
    SpocDashboardAdminWorkLog,
    SpocDashboardNetEnquiries,
    SpocDashboardCmNetEnquiries,
  },
  mounted() {
    this.getDashboardData();
  },
  methods: {
    getDashboardData() {
      let url = `${constants.MILES_CM_BACK}getPresignedUrlForSpocDashboardData?file_name=adminSpocDashboardExed.json`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("data", response);
          this.dashboard_data = response.data;
          // To get distinct values from an array
          this.teams = [
            ...new Set(this.dashboard_data.map((item) => item.sub_team)),
          ];
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    getTeamData() {
      this.team_data = []
      for (let i = 0; i < this.dashboard_data.length; i++) {
        if (this.selected_team == this.dashboard_data[i].sub_team) {
          this.team_data.push(this.dashboard_data[i]);
        }
      }
      console.log("team data",this.team_data)
      // Sort array of object with multiple value
        this.team_data.sort(function (a, b) {
          // Sort by value
          // If the first item has a higher value, move it down
          // If the first item has a lower value, move it up
          if (a.city_classification > b.city_classification) return 1;
          if (a.city_classification < b.city_classification) return -1;

          // If the value number is the same between both items, sort alphabetically
          // If the first item comes first in the alphabet, move it up
          // Otherwise move it down
          if (a.spoc_name > b.spoc_name) return 1;
          if (a.spoc_name < b.spoc_name) return -1;
      });  
      this.totalCalculate(this.team_data);
    },
    totalCalculate(Spocs) {
       this.total.Header = "TOTAL";
      this.total.missed_calls = Spocs.reduce(
        (n, { missed_calls }) => n + missed_calls,
        0
      );
      this.total.untracked = Spocs.reduce(
        (n, { untracked_calls }) => n + untracked_calls,
        0
      );
      this.total.to_update = Spocs.reduce(
        (n, { to_update_calls }) => n + to_update_calls,
        0
      );
      this.total.call_logs_today = Spocs.reduce(
        (n, { today_calls_logs }) => n + today_calls_logs,
        0
      );
      this.total.untapped = Spocs.reduce((n, { untapped }) => n + untapped, 0);
      this.total.delays = Spocs.reduce((n, { delays }) => n + delays, 0);
      this.total.today = Spocs.reduce((n, { today }) => n + today, 0);
      this.total.waiting = Spocs.reduce((n, { waiting_calls }) => n + waiting_calls, 0);
      this.total.mhps = Spocs.reduce((n, { mhp }) => n + mhp, 0);
      this.total.net_enquiries = Spocs.reduce(
        (n, { net_enquiries }) => n + net_enquiries,
        0
      );
      console.log("total", this.total);
      this.hidetotal=true;
      this.loaded = true;
    },
     getData(spoc_id, category) {
      let spocName = null;
      this.team_data.forEach((spoc) => {
        if (spoc.user_id === spoc_id) {
          spocName = spoc.spoc_name;
        }
      });
      
      let url = `${
        constants.ADMIN_SERVER_API
      }getIIMLSpocDashboardForAdminDetails`;
      let obj = {
        spoc_id: spoc_id,
        category: category,
      };
      axios
        .get(url, {
          params: obj,
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("data", response);
          if (category === "missed_calls" || category === "untracked") {
            eventbus.$emit(
              "spocDashboardAdmin-calllog",
              response.data,
              url,
              obj,
              category,
              spocName
            );
          } else if (category === "to_update") {
            eventbus.$emit(
              "spocDashboardAdmin-toUpdate",
              response.data,
              url,
              obj,
              category,
              spocName
            );
          } else if (
            category === "delays" ||
            category === "today" ||
            category === "waiting" ||
            category === "mhps" ||
            category === "untapped"
          ) {
            this.$store.commit("MOUNT_MWB_LEADS", response.data.data);
            eventbus.$emit("spocDashboardAdmin-WorkLog", [
              response,
              url,
              obj,
              spocName,
            ]);
          } else if (category === "net_enquiries") {
            this.$store.commit("MOUNT_MWB_LEADS", response.data.data);
            eventbus.$emit("spocDashboardAdmin-NetEnquiries", [
              response,
              url,
              obj,
              spocName,
            ]);
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
  },
};
</script>

<style>
.selectExample {
  width: 90%;
}
</style>